<template>
  <div>
    <Modal2
      :show="questionModal"
      :showHeader="false"
      :showCloseBtn="false"
      @close="closeQnsModal()"
    >
      <template v-slot:body>
        <div class="question-modal">
          <div class="question-header">
            <div class="flex items-center flex-wrap menus">
              <p
                class="m-0 pointer"
                @click="selectedModalTab = 1"
                v-if="QuestionDiagramURL || LatexUrl"
                :class="{ 'blue-font fw-600': selectedModalTab === 1 }"
              >
                Question
              </p>
              <p
                class="m-0 pointer"
                @click="selectedModalTab = 2"
                v-if="correctAnswer !== undefined"
                :class="{ 'blue-font fw-600': selectedModalTab === 2 }"
              >
                Result
              </p>
              <p
                class="m-0 pointer"
                @click="selectedModalTab = 3"
                v-if="KscDiagrams.length !== 0"
                :class="{ 'blue-font fw-600': selectedModalTab === 3 }"
              >
                Concept
              </p>
              <p
                class="m-0 pointer"
                @click="selectedModalTab = 4"
                v-if="FullSolutionURL"
                :class="{ 'blue-font fw-600': selectedModalTab === 4 }"
              >
                Full Solution
              </p>
              <p
                class="m-0 pointer"
                @click="selectedModalTab = 5"
                v-if="FullSolutionVideoUrl"
                :class="{ 'blue-font fw-600': selectedModalTab === 5 }"
              >
                Video
              </p>
              <p
                class="m-0 pointer"
                @click="selectedModalTab = 6"
                v-if="showFeedBackPoints"
                :class="{ 'blue-font fw-600': selectedModalTab === 6 }"
              >
                Feedback Points
              </p>
            </div>
            <p class="m-0">
              <span
                class="material-icons modal-close"
                style="color: #ffffff"
                @click="closeQnsModal()"
                >close</span
              >
            </p>
          </div>
          <div class="question-modal-body scroll-verticle-bar">
            <div class="question-box" v-if="selectedModalTab === 1">
              <img :src="QuestionDiagramURL" alt="" v-if="isLatex === 0" draggable="false" />
              <vue-mathjax
                :formula="LatexUrl"
                :options="VueMathJaxOptions"
                :safe="false"
                v-if="isLatex === 1"
                class="space-break fs-18 fw-500"
              />
              <div v-if="diagramUrl !== null">
                <img
                  class="col-sm-12 col-md-9"
                  :src="getAzureUrl(diagramUrl)"
                  alt=""
                  :style="{
                    width: `${diagramPosition[2] * 100}%`,
                    height: `${diagramPosition[3] * 100}%`,
                  }"
                  draggable="false"
                />
              </div>
              <div
                v-if="
                  isLatex === 1 &&
                  AnswerOption_A &&
                  AnswerOption_B &&
                  AnswerOption_C &&
                  AnswerOption_D
                "
              >
                <h1 class="fs-18 fw-600 dark-text m-0 mt-5 space-break">
                  <span v-if="!AnswerOption_A.includes('(A)')">(A)</span>
                  <vue-mathjax
                    :formula="AnswerOption_A"
                    :options="VueMathJaxOptions"
                    :safe="false"
                  />
                </h1>
                <h1 class="fs-18 fw-600 dark-text m-0 mt-5 space-break">
                  <span v-if="!AnswerOption_B.includes('(B)')">(B)</span>
                  <vue-mathjax
                    :formula="AnswerOption_B"
                    :options="VueMathJaxOptions"
                    :safe="false"
                  />
                </h1>
                <h1 class="fs-18 fw-600 dark-text m-0 mt-5 space-break">
                  <span v-if="!AnswerOption_C.includes('(C)')">(C)</span>
                  <vue-mathjax
                    :formula="AnswerOption_C"
                    :options="VueMathJaxOptions"
                    :safe="false"
                  />
                </h1>
                <h1 class="fs-18 fw-600 dark-text m-0 mt-5 space-break">
                  <span v-if="!AnswerOption_D.includes('(D)')">(D)</span>
                  <vue-mathjax
                    :formula="AnswerOption_D"
                    :options="VueMathJaxOptions"
                    :safe="false"
                  />
                </h1>
              </div>
            </div>
            <div class="concept-box" v-if="selectedModalTab === 2">
              <div v-if="correctAnswer === 1" class="flex" style="align-items: center">
                <img
                  :src="require(`../assets/correct-answer.svg`)"
                  class="center-align correct-image"
                  style="width: 100px"
                />
                <span
                  class="fw-700 fs-15 ln-20"
                  style="color: #16bcb2 !important; margin-left: 15px !important"
                  >Correct Answer !</span
                >
              </div>
              <div v-if="correctAnswer === 0" class="flex" style="align-items: center">
                <img
                  :src="require(`../assets/wrong-answer.svg`)"
                  class="center-align correct-image"
                  style="width: 100px"
                />
                <span
                  class="fw-700 fs-15 ln-20"
                  style="color: #ff7675 !important; margin-left: 15px !important"
                  >Wrong Answer !</span
                >
              </div>
            </div>
            <div class="concept-box" v-if="selectedModalTab === 3">
              <div class="concept-index-tab">
                <button
                  class="fs-16 concept-index-btn"
                  :class="{ conceptTabActive: conceptTabActive === index }"
                  v-for="(conceptImg, index) in KscDiagrams"
                  :key="index"
                  @click="viewConcept(conceptImg, index)"
                >
                  Concept {{ index + 1 }}
                </button>
              </div>
              <img
                :src="kscDiagramUrl"
                class="concept-image"
                style="max-height: max-content; padding: 14px"
                draggable="false"
              />
            </div>
            <div class="solution-box" v-if="selectedModalTab === 4">
              <img :src="FullSolutionURL" alt="" draggable="false" />
            </div>
            <div class="video-box" v-if="selectedModalTab === 5">
              <iframe
                :src="FullSolutionVideoUrl"
                frameborder="0"
                allow=" fullscreen; picture-in-picture"
                allowfullscreen
                class="videoModal w-100"
              ></iframe>
            </div>
            <div class="solution-box" v-if="selectedModalTab === 6">
              <div
                v-if="
                  feedBackPointsList !== null &&
                  feedBackPointsList !== undefined &&
                  feedBackPointsList.length > 0
                "
                id="table-wrapper"
              >
                <div id="table-scroll">
                  <table>
                    <thead></thead>
                    <tbody>
                      <tr v-for="(data, index) in this.feedBackPointsList" :key="index">
                        <td>{{ data }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div v-else>
                <p v-if="isEvaluated">No feedback points.</p>
                <p v-else>Question is not Evaluated yet.</p>
              </div>
            </div>
          </div>
        </div>
      </template>
    </Modal2>
  </div>
</template>

<script>
import { useToast } from "vue-toastification";
import Mobileapi from "../Mobileapi";
import Modal2 from "./Model2.vue";

const toast = useToast();

export default {
  data() {
    return {
      questionModal: false,
      QuestionDiagramURL: "",
      FullSolutionURL: "",
      KscDiagrams: [],
      kscDiagramUrl: "",
      FullSolutionVideoUrl: "",
      selectedModalTab: 1,
      conceptTabActive: 0,
      AnswerOption_A: null,
      AnswerOption_B: null,
      AnswerOption_C: null,
      AnswerOption_D: null,
      isLatex: 0,
      LatexUrl: "",
      diagramUrl: null,
      VueMathJaxOptions: {
        extensions: ["tex2jax.js"],
        showProcessingMessages: false,
        jax: ["input/TeX", "output/chtml"],
        lazyAlwaysTypeset: null,
        showMathMenu: false,
        tex2jax: {
          inlineMath: [
            ["$", "$"],
            ["\\(", "\\)"],
          ],
          displayMath: [
            ["$$", "$$"],
            ["\\[", "\\]"],
          ],
          processEscapes: false,
          processClass: "tex2jax_process",
        },
      },
    };
  },
  props: {
    questionId: Number,
    selectedTab: Number,
    correctAnswer: Number,
    showFeedBackPoints: Boolean,
    feedBackPointsList: Array,
    isEvaluated: Boolean,
  },
  created() {
    this.UserId = this.$store.getters.user.UserId;
    this.selectedModalTab = this.selectedTab ? this.selectedTab : 1;
    this.$store.dispatch("showLoader", true);
    this.kscDiagramUrl = "";
    this.FullSolutionURL = "";
    Mobileapi.getEntireDataOfQuestion(
      {
        QuestionId: this.questionId,
        UserId: this.UserId,
      },
      (response) => {
        if (response.responseCode === 200 && Object.keys(response.data).length > 0) {
          this.questionModal = true;
          const QuestionDetail = response.data;
          if (QuestionDetail.LatexStatus === "Y" && QuestionDetail.Q_LATEX) {
            this.LatexUrl = QuestionDetail.Q_LATEX;
            this.AnswerOption_A = QuestionDetail.AnswerOptionA;
            this.AnswerOption_B = QuestionDetail.AnswerOptionB;
            this.AnswerOption_C = QuestionDetail.AnswerOptionC;
            this.AnswerOption_D = QuestionDetail.AnswerOptionD;
            if (QuestionDetail?.DiagramUrl) {
              const qnsDiagramDetail = JSON.parse(QuestionDetail?.DiagramUrl);
              this.diagramUrl = qnsDiagramDetail?.diagram_0?.url;
              this.diagramPosition = qnsDiagramDetail?.diagram_0?.position;
            }
            this.isLatex = 1;
          } else {
            this.QuestionDiagramURL = QuestionDetail.QuestionDiagramURL;
            this.isLatex = 0;
          }
          this.FullSolutionURL = response.data.FullSolutionURL;
          this.KscDiagrams = response.data.KscDiagrams;
          this.kscDiagramUrl = this.KscDiagrams[0];
          this.FullSolutionVideoUrl = response.data.FullSolutionVideoUrl;
          this.$store.dispatch("showLoader", false);
        }
        if (response.responseCode === 500) {
          this.questionModal = false;
          this.$store.dispatch("showLoader", false);
          toast.error(`${response.message}`);
        }
      },
    );
  },
  components: {
    Modal2,
  },
  methods: {
    viewConcept(kscDiagrams, index) {
      this.conceptTabActive = index;
      this.kscDiagramUrl = kscDiagrams;
    },
    closeQnsModal() {
      this.questionModal = false;
      this.$emit("closeModal", false);
    },
    getAzureUrl(imageUrl) {
      if (imageUrl.startsWith("~")) {
        imageUrl = imageUrl.replace("~", process.env.VUE_APP_STORAGE_URL);
      }
      return imageUrl;
    },
  },
};
</script>

<style scoped>
.question-modal {
  border-radius: 5px;
  height: 100%;
  width: 100%;
}

.question-header {
  width: 100%;
  background-color: #f3f5fc;
  border-radius: 5px 5px 0px 0px;
  display: flex;
  /* gap: 20px; */
  justify-content: space-between;
  align-content: center;
  padding: 20px;
}

.menus {
  gap: 50px;
  margin-right: 20px;
}

.question-modal img {
  width: 100%;
  height: 100%;
}

.question-modal-body {
  padding: 20px;
  height: 600px;
  max-height: 65vh;
  width: 1000px;
  overflow: scroll;
}

.video-box {
  display: flex;
  justify-content: center;
}

.videoModal {
  width: 600px;
  height: 340px;
  padding: 12px 11px 5px;
}

.modal-close {
  top: 0;
  right: 0;
  background-color: #ff7675;
  border-radius: 5px;
  padding: 5px;
  cursor: pointer;
}

.concept-index-tab {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.concept-index-btn {
  border: 1px solid #d6daf7;
  background: #f8f8f8;
  border-radius: 4px;
  opacity: 0.65;
  cursor: pointer;
  padding: 10px 20px;
  color: #333;
}

.conceptTabActive {
  opacity: 1;
  background: #f0f2ff;
  border: 1px solid #333;
  font-weight: 600;
}

.menus p {
  font-size: 18px;
}

@media screen and (max-width: 800px) {
}

@media screen and (max-width: 600px) {
  .menus {
    gap: 10px;
  }

  .menus p {
    font-size: 10px;
  }

  .videoModal {
    width: 350px;
    height: 270px;
    padding: 12px 11px 5px;
  }

  .concept-index-btn {
    padding: 5px 8px;
  }
}
</style>
